*, *::before, *::after {
    box-sizing: inherit;
  }
  
  html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    /*overflow-x: scroll;*/
  }
  
  html body {
    padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom,20px) env(safe-area-inset-left, 20px);
    margin: 0;
    overflow-y: auto;
    /*overflow-y: inherit;*/
  }